<script>
  import { onMount } from "svelte";

  import RangeSlider from "svelte-range-slider-pips";
  import { route, searchResults } from "./store.js";

  let min = 10;
  let max = 50;
  let step = 5;
  let range = true;
  let price = [10, 50];

  let schema;

  let category = [];
  let food = [];
  let country = [];

  onMount(() => {
    fetch("process.env.SCHEMAURL").then((data) => {
      data.json().then((json) => {
        schema = json;
      });
    });
  });

  function findSchema(key) {
    return schema.schema.find((item) => item.key === key).templateOptions
      .options;
  }

  function findLabel(key) {
    return schema.schema.find((item) => item.key === key).templateOptions.label;
  }

  function makeSelection(key, value) {
    switch (key) {
      case "category":
        /* Example for multi select */

        // if (!category.includes(value)) {
        //   const current = category;
        //   current.push(value);
        //   category = current;
        // } else {
        //   category = category.filter((item) => item !== value);
        // }
        // break;

        category = value;
        break;

      case "food":
        food = value;
        break;

      case "country":
        country = value;
        break;
    }
  }

  async function submitForm() {
    console.log({ category, food, country, price });

    //    @TODO: make a post request to backend

    // const res = await fetch('BACKEND_URL', {
    // 		method: 'POST',
    // 		body: JSON.stringify({
    // 			category: category,
    // 			food: food,
    //       country: country
    // 		})
    // 	})

    //   const json = await res.json();

    // For testing we fetch from data.json
    fetch("process.env.DATAURL").then((data) => {
      data.json().then((json) => {
        console.log(json);
        searchResults.set(json.data);
        route.set("results");
        window.scrollTo(0, 0);
      });
    });
  }
</script>

{#if schema}
  <main>
    <nav>
      <h2 on:click={() => route.set("intro")}>
        <img src="./assets/images/back.svg" alt="Atpakaļ" /> Back
      </h2>
      <img src="./assets/images/menu.svg" class="menu" />
    </nav>
    <div class="content">
      <RangeSlider
        id="slider"
        {min}
        {max}
        {step}
        pips="true"
        {range}
        float
        values={price}
        all="label"
      />

      <section>
        <header>
          <h2>{findLabel("category")}</h2>
        </header>
        <div>
          {#each findSchema("category") as item}
            <button
              class="button"
              class:active={category.includes(item.value)}
              disabled={item.disabled}
              on:click={() => makeSelection("category", item.value)}
              >{item.label}</button
            >
          {/each}
        </div>
      </section>

      <section>
        <header>
          <h2>{findLabel("food")}</h2>
        </header>
        <div>
          {#each findSchema("food") as item}
            <button
              class="button"
              class:active={food.includes(item.value)}
              disabled={item.disabled}
              on:click={() => makeSelection("food", item.value)}
              >{item.label}</button
            >
          {/each}
        </div>
      </section>

      <section>
        <header>
          <h2>{findLabel("country")}</h2>
        </header>
        <div>
          {#each findSchema("country") as item}
            <button
              class="button"
              class:active={country.includes(item.value)}
              disabled={item.disabled}
              on:click={() => makeSelection("country", item.value)}
              >{item.label}</button
            >
          {/each}
        </div>
      </section>
    </div>

    <footer>
      <button
        class="button button--medium button--primary"
        on:click={() => submitForm()}>Find my wine</button
      >
    </footer>
  </main>
{/if}

<style>
  :global(#slider) {
    --range-slider: #525252;
    --handle: #d8b86c;
    --handle-inactive: #d8b86c;
    --range-range: #d8b86c;
    --range-range-inactive: #d8b86c;
    --range-inactive: #d8b86c;
    --handle-focus: #917a46;
    --pip-active-text: #d8b86c;
    --pip-text: white;
  }

  section {
    margin-top: 2rem;
  }

  .content {
    margin-top: 5rem;
  }

  section header {
    width: 100%;
    color: #d1b26a;
    text-transform: uppercase;
    background: linear-gradient(
      90deg,
      rgba(54, 57, 56, 1) 0%,
      rgba(20, 21, 29, 1) 100%
    );
    margin: 1.5rem 0;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    text-align: center;
  }

  section header h2 {
    margin: 0.4rem;
  }

  section div {
    color: white;
    display: flex;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 1rem;
  }

  section div button {
    width: 100%;
    white-space: nowrap;
    padding: 1.5rem 0.2rem;
    font-size: 0.9rem;
    background: #4e4d4d;
    color: white;
  }

  button[disabled] {
    opacity: 0.5;
  }

  button.active {
    background-color: #ed1f24;
  }

  footer {
    text-align: center;
    margin-top: 2rem;
  }

  nav {
    position: fixed;
    z-index: 2000;
  }
</style>

<script>
  import { route } from "./store";
  import Intro from "./Intro.svelte";
  import Search from "./Search.svelte";
  import Results from "./Results.svelte";
  import Result from "./Result.svelte";

</script>

<div>
  {#if $route == "intro"}
    <Intro />
  {/if}
  {#if $route == "search"}
    <Search />
  {/if}
  {#if $route == "results"}
    <Results />
  {/if}
  {#if $route == "result"}
  <Result />
  {/if}
</div>

<style>
  div {
    margin: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    }
</style>

<script>
  import { searchResults, route, selectedWine } from "./store.js";

  function showWine(url) {
    console.log("Show on shelf", url);
    buttonIsActive = true;

    fetch(url).then((data) => {
      data.json().then((json) => {
        buttonIsActive = false;
      });
    });
  }

  let buttonIsActive = false;
</script>

{#if $selectedWine}
  <main>
    <nav>
      <h2 on:click={() => route.set("results")}>
        <img src="./assets/images/back.svg" alt="Atpakaļ" /> Back
      </h2>
      <h1>{$selectedWine.title}</h1>
    </nav>

    <article>
      <img src={$selectedWine.image} alt="" />

      <div>
        <hr class="vertical" />
        <hr class="horizontal" />
        <p>{$selectedWine.description}</p>
        <h2>{$selectedWine.price}</h2>
        <h3>{$selectedWine.country}</h3>
      </div>
    </article>

    <section>
      <button
        class="button button--primary button--medium"
        class:isActive={buttonIsActive}
        on:click={showWine($selectedWine.callback_url)}>Show on shelf</button
      >
    </section>
  </main>
{/if}

<style>
  main {
    background-image: url("../assets/images/selected.png");
    background-size: cover;
  }

  nav {
    text-align: center;
    justify-content: center;
    position: fixed;
    z-index: 2000;
  }

  nav h1 {
    color: #d1b26a;
    font-size: 1.8rem;
    margin-left: auto;
    margin-right: auto;
    padding-right: 8rem;
  }
  nav h2 {
    margin-left: 1rem;
  }

  article {
    text-align: center;
    max-width: 80vw;
    margin: 9rem auto 0 auto;
  }

  article img {
    border-radius: 10px;
    object-fit: contain;
    z-index: 1;
    position: relative;
  }

  article div {
    margin-top: -4rem;
    border-radius: 15px;
    backdrop-filter: blur(9px) saturate(180%);
    -webkit-backdrop-filter: blur(9px) saturate(180%);
    background-color: rgba(25, 26, 29, 0.75);
    border-radius: 12px;
    border: 1px solid rgba(255, 255, 255, 0.125);
    padding: 5rem 4rem;
    color: white;
  }

  article p {
    font-size: 1.4rem;
  }

  hr {
    border-color: #d6b66b;
    height: 3px;
  }

  hr.vertical {
    background: #d6b66b;
    width: 2px;
    height: 100px;
    border: none;
    margin-bottom: 1rem;
  }

  section {
    text-align: center;
    margin-top: 2rem;
  }

  button img {
    width: 1rem;
    margin-right: 1rem;
    position: relative;
    top: 3px;
  }

  .isActive {
    opacity: 0.5;
  }
</style>

<script>
  import { searchResults, route, selectedWine } from "./store.js";

  function openWine(wine) {
    route.set("result");
    selectedWine.set(wine);
    window.scrollTo(0, 0);
  }

  function showWine(url, index) {
    console.log("Show on shelf", url);
    activeButton = index;

    fetch(url).then((data) => {
      data.json().then((json) => {
        activeButton = null;
      });
    });
  }

  let activeButton = null;
</script>

{#if $searchResults.length > 0}
  <main>
    <nav>
      <h2 on:click={() => route.set("search")}>
        <img src="./assets/images/back.svg" alt="Atpakaļ" /> Back
      </h2>
      <h1>Selected wine</h1>
      <img src="./assets/images/menu.svg" class="menu" />
    </nav>

    <section class="content">
      {#each $searchResults as result, i}
        <article on:click={openWine(result)} class="result">
          <div class="left">
            <img src={result.image} alt={result.title} />
          </div>
          <div class="right">
            <h2>
              {result.title}
            </h2>
            <h4>{result.country}</h4>
            <h3>{result.price}</h3>
          </div>
          <button class="button button--secondary button--medium nowrap"
            >More info</button
          >
          <button
            on:click|stopPropagation={showWine(result.callback_url, i)}
            class:isActive={activeButton == i}
            class="button button--primary button--medium nowrap"
            >Show on shelf</button
          >
        </article>
      {/each}
    </section>
  </main>
{:else}
  <main class="not-found">
    <section>
      <h1>Wine not <br />found</h1>
      <button
        class="button button--primary button--large"
        on:click={() => route.set("search")}>Back</button
      >
    </section>
  </main>
{/if}

<style>
  nav h1 {
    margin-left: auto;
    color: #d1b26a;
    font-size: 1.8rem;
    margin-right: auto;
    padding-right: 8rem;
  }
  .not-found {
    background-image: url("../assets/images/notfound.png");
    background-size: cover;
    height: 100vh;
    width: 100vw;

    color: white;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .not-found section {
    margin-top: 3rem;
    backdrop-filter: blur(9px) saturate(180%);
    -webkit-backdrop-filter: blur(9px) saturate(180%);
    background-color: rgba(25, 26, 29, 0.75);
    border-radius: 12px;
    border: 1px solid rgba(255, 255, 255, 0.125);
    margin: auto;
    padding: 10rem 8rem 3rem 8rem;
  }

  .not-found h1 {
    font-size: 4rem;
    text-transform: uppercase;
  }

  .not-found button {
    margin-top: 3rem;
  }

  .result {
    display: flex;
    align-items: center;
    color: white;
  }
  .result .left img {
    width: 200px;
    height: 200px;
    object-fit: contain;
    border-radius: 15px;
    position: relative;
    z-index: 1;
    background-color: white;
    padding: 10px 0;
  }

  .result {
    margin-bottom: 1rem;
  }

  .result .right {
    backdrop-filter: blur(9px) saturate(180%);
    -webkit-backdrop-filter: blur(9px) saturate(180%);
    background-color: rgba(70, 70, 71, 0.75);
    border-radius: 12px;
    border: 1px solid rgba(255, 255, 255, 0.125);
    height: 150px;
    width: 100%;
    margin-left: -10px;
    padding: 1rem 2rem;
  }

  hr {
    border-color: #d6b66b;
    height: 3px;
  }
  h2 {
    margin: 0;
  }

  .button--secondary {
    margin-left: 1rem;
    margin-right: 1rem;
    background: #4e4d4d;
  }

  nav {
    position: fixed;
    z-index: 2000;
  }

  .content {
    margin-top: 6rem;
  }

  .isActive {
    opacity: 0.5;
  }
</style>

<script>
  import { route } from "./store";
  import Modal from "svelte-simple-modal";
  import Video from "./Video.svelte";
  import { writable } from "svelte/store";
  function goToSearch() {
    route.set("search");
  }

  export const modal = writable(null);

  function showModal() {
    modal.set(Video);
  }
</script>

<div class="wrapper">
  <!-- <video
    autoplay
    loop
    preload="all"
    playsinline
    src="/assets/video/bg.mp4"
    id="video"
    muted
  /> -->
  <div class="logo">
    <img
      src="./assets/images/logo-vvt.png"
      alt="Spirits & Wine"
      class="logo__image"
    />
  </div>

  <section>
    <div class="content">
      <h1>Wine Matchmaker</h1>
      <button class="button button--large button--primary" on:click={goToSearch}
        >Start here</button
      >
    </div>

    <!-- <button class="button button--small button--secondary" on:click={showModal}
      >Noskaties pamācību</button
    > -->
  </section>

  <Modal show={$modal} />
</div>

<style>
  .wrapper {
    background: black;
    background: url("../assets/images/wines.png");
    height: 100vh;
    background-size: cover;
  }

  .logo {
    margin: 15px;
    position: relative;
    z-index: 2;
  }

  #video {
    position: fixed;
    z-index: 0;
    user-select: none;
    width: 100vw;
    height: 100vh;
    object-fit: contain;
  }

  .content {
    width: 80%;
    margin: 150px auto 0 auto;
    color: white;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;

    border-radius: 20px;
    backdrop-filter: blur(9px) saturate(192%);
    -webkit-backdrop-filter: blur(9px) saturate(192%);
    background-color: rgba(40, 41, 41, 0.59);
    margin-bottom: 2rem;
    position: relative;
    z-index: 100;
  }

  h1 {
    text-transform: uppercase;
    font-size: 4rem;
    margin-top: 50px;
    margin-bottom: 1rem;
  }

  section {
    padding: 15px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
  }

  .logo img {
    width: 150px;
  }

  .button--large {
    margin: 2rem auto;
  }

  .button--small {
    max-width: 300px;
    margin: 30px auto;
  }
</style>
